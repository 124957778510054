(function ($) {
    updateProgressCircle();

    function updateProgressCircle() {
        var scrollPercentage = getScrollPercentage();
        var progressCircle = document.querySelector('[data-progress-circle]');
        var percentEl;
        var circleEl;

        if (progressCircle && scrollPercentage) {
            percentEl = progressCircle.querySelector('[data-progress-circle-text]');
            circleEl = progressCircle.querySelector('[data-progress-circle-circle]');

            percentEl.textContent = scrollPercentage + '%';
            circleEl.style.strokeDasharray = scrollPercentage + ' 100';
        }
    }

    function getScrollPercentage() {
        var articleBody = document.getElementsByClassName('article__body')[0];
        var maxHeight;

        if (articleBody) {
            maxHeight = articleBody.scrollHeight;

            return Math.ceil(Math.min(getCurrentScroll() / maxHeight, 1) * 100);
        }

        return null;
    }

    // cross browser safe way of getting the current scroll position
    function getCurrentScroll() {
        return (
            window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
        );
    }

    $(document).scroll(function () {
        updateProgressCircle();
    });
})(jQuery);
